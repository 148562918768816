
.header {
  position:fixed;
  top:0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #1f2d5a;
  height: 50px;
  padding: 0;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.header a {
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 3px;
  margin: 0;
  border: 0;
  text-decoration: none;
  font-size: 15px;
}

.header .logo {
  max-height:50px;
}

.header p {
  padding: 0px;
  margin: 0px;
}

.active {
  background-color: #04aa6d;
  border: 0;
  color: white;
}

.header-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #1f2d5a;
  height: 50px;
  padding: 0;
  margin: 0;
  width: 50%;
  transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  box-sizing: border-box;
}

.header-left.hidden {
  width: 0;
  overflow: hidden;
  padding: 0;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #1f2d5a;
  height: 50px;
  padding: 0;
  margin: 0;
  width: 50%;
  transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  box-sizing: border-box;
}

.header-right.full {
  width: 100%;
}

.search-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.search-box {
  background-color: white;
  color: #151515;

  height: 34px;
  padding: 0 10px 0 40px; /* Adjust padding to include space for the search icon */
  margin: 7px 0;
  width: 100%;
  font-size: 16px;
  transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  box-sizing: border-box;
}
.search-box.focused {
  width: 100%;
}

input:focus {
  outline: none;
}

#search-icon {
  background-color: white;
  border-left: 1px solid #ccc; /* Faint grey right border for the icon */
  border-top-left-radius: 5px; /* Match the border radius */
  border-bottom-left-radius: 5px; /* Match the border radius */
  padding: 10px;
  height: 34px; /* Match the search box height */
  box-sizing: border-box;
  margin-right: -5px; /* Ensure no gap between icon and search box */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #04aa6d; /* Set the icon color */
}

.clear-text {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  padding: 0;
}

.stack {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
}

/* Social icons styles */
.social-icons {
  display: flex;
  gap: 1px;
}

.social-icons a {
  color: #f2f2f2;
  font-size: 18px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #04aa6d;
}

.nav-links {
  display: flex; /* Flexbox to align links horizontally */
  align-items: center;
  gap: 20px; /* Space between the links */
}

.nav-links a {
  text-decoration: none;
  color: #f2f2f2;
  font-size: 15px;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #04aa6d;
}

/* Hamburger menu styles */
.hamburger-menu button {
  background: none;
  border: none;
  cursor: pointer;
  color: #f2f2f2;
  font-size: 1.5rem;
  display: block; /* Initially hidden */
}

.menu-dropdown {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #1f2d5a;
  display: none; /* Initially hidden */
  flex-direction: column;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 20;
}

.menu-dropdown a {
  padding: 10px;
  text-decoration: none;
  color: #f2f2f2;
  border-bottom: 1px solid #ddd;
}

.menu-dropdown a:hover {
  background-color: #f4f4f4;
  color: #1f2d5a;
}

/* Media query for smaller screens */
@media (max-width: 810px) {
  .header-left {
    transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .header-left.hidden {
    width: 0;
    overflow: hidden;
    padding: 0;
  }

  .header-right {
    transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    width: 100%;
    box-sizing: border-box;
    overflow: hidden; /* Ensure no overflow */
  }

  .search-container {
    width: 100%;
  }

  .search-box {
    transition: width 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    width: 100%;
    margin: 7px 0;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .search-box.focused {
    width: 100%; /* Ensure it doesn't overflow */
  }

  .nav-links {
    display: none; /* Hide nav links */
  }

  .hamburger-menu button {
    display: block; /* Show hamburger menu */
  }

  .menu-dropdown {
    display: flex; /* Show dropdown when active */
    width: 100%;
  }
}

