/* Article Container */
.articles-container {
  max-width: 1200px; /* Ensuring consistent max-width */
  margin: 50px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
}

/* Articles List */
.articles-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

/* Individual Article Styling */
.article {
  width: calc(33.333% - 20px); /* Three columns layout */
  position: relative;
  overflow: hidden;
  text-decoration: none; /* Remove underline from the link */
  color: inherit; /* Maintain text color */
  cursor: pointer; /* Change cursor to pointer on hover */
}

/* Image Container and Hover Effects */
.image-container {
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 15px;
  overflow: hidden;
}

.article-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-container:hover .article-image {
  transform: scale(1.1); /* Zoom effect on hover */
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
  opacity: 1; /* Show overlay on hover */
}

.overlay-title {
  color: white;
  font-size: 1.5em;
  text-align: center;
  padding: 0 10px;
}

/* Article Text Styling */
.article-title {
  font-family: 'Georgia', serif; /* Stylish font for the title */
  font-size: 1.4em;
  text-align: center;
  margin: 15px 0;
}

.article-excerpt {
  font-size: 1em;
  color: #555;
  text-align: justify;
  padding: 0 10px;
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .article {
    width: calc(50% - 20px); /* Two columns for tablet */
  }
}

@media (max-width: 480px) {
  .article {
    width: 100%; /* Full width for mobile */
  }
}
