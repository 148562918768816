

.results-list {
    width: 90%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    text-align: left; /* Use 'left' instead of 'baseline' for text alignment */
    margin-left: 0.5rem;
    font-size: 1rem;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for better aesthetics */
    gap: 0.1rem; /* Increased gap for better spacing between items */
    cursor: pointer;
    transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
    position:fixed;
    top:45px;
    z-index:13;
  }
  
  .results-list img {
    height: 40px;
    width:  40px;
  }
  
  .results-list .search-icon {
    color:#190a45;
    max-width: 50px; /* Set a maximum width for images */
    max-height: 50px; /* Set a maximum height for images */
    border-radius: 5px; /* Rounded corners for images */
    margin-right: 0.75rem; /* Space between image and text */
    object-fit:contain; /* Ensure the image covers the given dimensions */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  
  }
  .results-list:hover {
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2); /* Slightly more pronounced shadow on hover */
  }
  
  .results-list .item {
    display: flex;
    align-items: center; /* Vertically center items */
    padding: 0.75rem; /* Padding for better spacing inside items */
    border-bottom: 1px solid #f0f0f0; /* Divider line between items */
  }
  
  .results-list .item:last-child {
    border-bottom: none; /* Remove border for the last item */
  }
  
  .results-list .item:hover {
    background-color: #f9f9f9; /* Light background change on hover for items */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  
  .results-list .item:hover {
    background-color: #c8d6d1;
  }
  
  .results-list .item img {
    max-width: 50px; /* Set a maximum width for images */
    max-height: 50px; /* Set a maximum height for images */
    border-radius: 5px; /* Rounded corners for images */
    margin-right: 0.75rem; /* Space between image and text */
    object-fit: cover; /* Ensure the image covers the given dimensions */
    transition: transform 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .results-list .item img:hover {
    transform: scale(1.05); /* Slight zoom-in effect on hover */
  }