/* Article Container */
.article-container {
  display: flex; /* Use flexbox to allow for the sidebar */
  max-width: 1200px;
  margin: 50px auto;
  padding: 30px 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  line-height: 1.8; /* Increase line height for better readability */
}

/* Article Content Specific Styles */
.article-content h2 {
  font-family: 'Arial', sans-serif;
  font-size: 24px;
  color: #1f2d5a;
  text-align: center; /* Center align the heading */
  margin-bottom: 20px; /* Space below the heading */
}

.article-content img {
  display: block; /* Make the image a block element */
  margin: 0 auto; /* Center the image horizontally */
  border-radius: 10px; /* Optional rounded corners */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
  max-width: 200px;
}

.article-content ul {
  list-style-type: none; /* Remove default bullet points */
  padding: 0; /* Remove default padding */
  margin: 20px 0; /* Space above and below the list */
  font-family: 'Arial', sans-serif;
}

.article-content ul li {
  margin-bottom: 15px; /* Add more space between list items */
  color: #333; /* Darker text color for readability */
}

.article-content ul li strong {
  color: #0fb2e4; /* Different color for strong elements */
}

.article-content p {
  line-height: 1.6; /* Improve readability */
  color: #333; /* Dark text color */
  font-family: 'Arial', sans-serif;
  margin-top: 20px; /* Space above the paragraph */
}

.article-content hr {
  border: 1px solid #ddd; /* Light gray border for the horizontal rule */
  margin: 30px 0; /* Space above and below the line */
}

/* Existing Styles for Article */
.article-content {
  flex: 1; /* Take up the remaining space */
  padding-right: 20px; /* Add some space to the right for the sidebar */
}

.article-container h1 {
  color: #1f2d5a;
  text-align: center;
}

.article-container p {
  line-height: 1.6;
  color: #333;
  text-align: justify;
}

.article-container a {
  color: #0fb2e4;
  text-decoration: underline;
}

.article-container a:hover {
  text-decoration: underline;
}

.article-section {
  display: flex;
  align-items: flex-start; /* Align items at the top */
}

.right {
  margin-left: 20px; /* Adds space between the image and the text */
  max-width: 200px; /* Adjust as needed to control the image size */
}

/* Sidebar Styling */
.right-sidebar {
  width: 250px;
  padding: 20px;
  background-color: #f9f9f9; /* Softer light background */
  border-left: 2px solid #0fb2e4; /* Add a bold accent color on the left */
  border-radius: 10px; /* Rounded corners for modern look */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for depth */
  position: relative;
  height: auto;
  top: 0;
}

/* Sidebar Heading Styling */
.right-sidebar h2 {
  font-family: 'Arial', sans-serif;
  font-size: 20px;
  color: #1f2d5a; /* Darker, bold color for heading */
  margin-top: 0;
  margin-bottom: 15px;
  border-bottom: 2px solid #0fb2e4; /* Underline with accent color */
  padding-bottom: 5px;
}

/* Sidebar List */
.right-sidebar ul {
  list-style-type: none; /* Remove default list styles */
  padding: 0;
}

/* Sidebar List Items */
.right-sidebar li {
  margin: 15px 0; /* Increase space between items */
}

/* Sidebar Links */
.right-sidebar a {
  text-decoration: none; /* Remove underline */
  color: #1f2d5a; /* Matching with headings */
  font-weight: bold;
  display: block; /* Block level for better clickable area */
  padding: 10px;
  background-color: #eaf4fb; /* Light blue background for the links */
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.right-sidebar a:hover {
  background-color: #0fb2e4; /* Darker blue on hover */
  color: white; /* White text on hover for contrast */
}



/* Sidebar Widget Section */
.right-sidebar .widget {
  margin-bottom: 30px;
}



/* Media Queries for Responsiveness */
@media (max-width: 768px) {
  .article-container {
    flex-direction: column; /* Stack the content vertically on small screens */
    padding: 10px;
  }

  .right-sidebar {
    width: 100%; /* Make sidebar full width on smaller screens */
    border-left: none; /* Remove the border */
    border-top: 1px solid #ddd; /* Add a top border instead */
  }

  .article-content {
    padding-right: 0; /* Remove padding on smaller screens */
  }

  .article-content img {
    max-width: 100%; /* Make the image full width on smaller screens */
    margin: 20px auto; /* Keep it centered */
  }
}
