/* Carousel Container */
.carousel {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 0;
  position:relative;
}

/* Image Styling */
.img1 {
  width: 100%;
  height: auto;
  max-height: 150px;
  object-fit: contain;
  border-radius: 5%;
}

/* Slider Container */
.slider {
  width: 100%;
  padding: 0px 0;
  border-radius: 10px;
  position: relative;
  overflow: hidden; /* Ensure overflow content is hidden */
  box-sizing: border-box;
}

.slider a {
  text-decoration: none;
  color: #0056b3;
  cursor: pointer;
  font-weight: bold;
}

/* Slider Product Container */
.slider-product-container {
  background-color: rgb(255, 253, 253);
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 10px;
  border-radius: 10px;
  display:flex;
  align-items: stretch;
  justify-content:baseline;
  height:max-content;
  overflow: hidden; /* Ensure overflow content is hidden */
}

/* Slider Product */
.slider-product {
  background-color: #F3F3F5;
  width: 90%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
  padding: 15px;
  margin: 0px;
  box-sizing: border-box;
}

/* Header Paragraph */
p.header {
  font-weight: bold;
  padding: 0px;
  margin: 0px;
}

/* Title Styling */
.title {
  margin: 0 auto;
  max-width: 100%; /* Ensure it does not exceed the container's width */
  font-size: 20px;
  padding: 10px;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center; /* Center the text */
}

/* Description Styling */
.description {
  margin: 20px auto;
  width: 80%;
  font-size: 25px;
}

/* Product Name */
.slider-product p {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

/* Product Price */
.slider-product .price {
  color: #e91e63;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* Custom Arrows */
.slick-prev.custom-arrow, .slick-next.custom-arrow {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #04020c;
  border: 2px solid #0c0c0c; /* Dark border */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #110f0f;
  box-shadow: none; /* No shadow */
  opacity: 0.8;
  transition: opacity 0.3s, transform 0.3s;
  z-index: 3;
}

.slick-prev.custom-arrow:hover, .slick-next.custom-arrow:hover {
  opacity: 1;
  transform: scale(1.1);
}

.slick-prev.custom-arrow {
  left: -15px;
}

.slick-next.custom-arrow {
  right: -15px;
}

.slick-prev:before, .slick-next:before {
  font-size: 20px;
  content: '\2190'; /* Unicode left arrow */
}

.slick-next:before {
  content: '\2192'; /* Unicode right arrow */
}
.was-price {
  font-size: 1rem; /* Adjust the size as needed */
  color: #1e6980; /* Light gray color for the crossed-out price */
  text-decoration: line-through; /* Crosses out the text */
  margin-top: 0.25rem; /* Space above the was-price element */
  display: block; /* Ensure it takes up a new line */
}


/* Responsive Adjustments */
@media (max-width: 1024px) {
  .slider {
    clip-path: inset(0 -120px 0 0); /* Adjust clip path for smaller screens */
  }
}

@media (max-width: 600px) {
  .slider {
    clip-path: inset(0 -75px 0 0); /* Adjust clip path for smaller screens */
  }
}

@media (max-width: 480px) {
  .slider {
    clip-path: inset(0 -50px 0 0); /* Adjust clip path for smaller screens */
  }
}


