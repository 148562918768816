/* General Container Styles */
.home-container {
  margin: 0 auto;
  max-width: 1200px;
  box-sizing: border-box;
  padding: 20px; /* Added padding for spacing */
}

/* Header Container Styles */
.home-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px; /* Increased margin for breathing space */
  border-bottom: 2px solid #e0e0e0; /* Subtle divider line */
  padding-bottom: 10px;
}

/* Header Styles */
.home-header {
  font-weight: 700; /* Updated for a more refined look */
  font-size: 1.75rem; /* Larger and more prominent header */
  color: #003366; /* Darker, more sophisticated blue */
  margin-bottom: 0;
}

/* Header Link Styles */
.home-header-link {
  font-size: 1rem;
  color: #003366; /* Consistent color theme */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth transition */
}

.home-header-link:hover {
  color: #0056b3; /* Change color on hover */
}

/* Hot Container Styles */
.home-hot {
  padding: 10px;
  margin: auto;
  text-align: center;
  border-radius: 15px;

}

/* Ensure all links have the color #003366 */
.home-featured .home-container a {
  color: #003366; /* Updated link color */
  text-decoration: none;
  transition: color 0.3s ease; /* Smooth color transition */
}

.home-featured .home-container a:visited {
  color: #003366;
}

.home-featured .home-container a:hover {
  color: #0056b3;
}

.home-featured .home-container a:active {
  color: #002244; /* Darker on active */
}

/* Media Query for Smaller Screens */
@media (max-width: 768px) {
  .home-header-container {
    flex-direction: column;
    align-items: flex-start;
    border-bottom: none; /* Remove border on smaller screens */
  }

  .home-header-link {
    margin-top: 10px;
  }
}

/* Menu Styles */
.home-menu {
  margin-bottom: 10px;
  overflow-x: auto;
  padding: 10px 0; /* Added padding for spacing */

}

.home-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
}

.home-menu li {
  margin: 0 10px;
  padding: 12px 24px; /* Larger padding for a more premium feel */
  background-color: #e0e0e0; /* Slightly darker background */
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 20px;
  flex: 0 0 auto;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-menu li:hover {
  background-color: #c0c0c0;
  color: #ffffff;
  transform: scale(1.05); /* Slight zoom on hover */
}

/* Media Query for Larger Screens */
@media (min-width: 600px) {
  .home-menu ul {
    justify-content: center;
  }

  .home-menu li {
    flex: 1;
    text-align: center;
  }
}

/* Featured Section Styles */
.home-featured {
  display: flex;
  justify-content: center;
  gap: 30px; /* Increased gap for better spacing */
  padding: 40px; /* Increased padding for spacious feel */
  margin-top: 50px;
  box-sizing: border-box;
  max-width: 1200px;
  align-items: center;
  margin: 0 auto;
  background-color: #fafafa; /* Soft background color */
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
}

.home-featured-left,
.home-featured-right {
  display: flex;
  flex-direction: column;
  width: 48%;
  gap: 20px; /* Increased gap for better spacing */
}

.large-image,
.small-image,
.tall-image,
.square-image {
  width: 100%;
  height: auto;
  border-radius: 15px; /* Larger radius for smoother edges */
  object-fit: contain;
  transition: transform 0.3s ease; /* Smooth image scaling on hover */
  margin-top: 15px;
}

.large-image:hover,
.small-image:hover,
.tall-image:hover,
.square-image:hover {
  transform: scale(1.02); /* Slight zoom on hover for engagement */
}

.small-images {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.small-images a {
  display: block;
  width: 100%;
  text-decoration: none;
}

/* Featured Right Section Styles */
.home-featured-right {
  display: flex;
  flex-direction: row;
  gap: 15px; /* Slightly increased gap */
}

.tall-image-container {
  width: 50%;
}

.square-images-container {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Increased gap for better spacing */
  width: 50%;
}

/* Media Query for Even Smaller Screens */
@media (max-width: 810px) {
  .home-featured {
    flex-direction: column;
    gap: 20px;
  }

  .home-featured-left,
  .home-featured-right {
    width: 100%;
  }

  .home-featured-right {
    flex-direction: column;
  }

  .tall-image-container,
  .square-images-container {
    width: 100%;
  }

  .square-images-container {
    flex-direction: row;
  }
}

/* Corrected Media Query for Small Screens */
@media (max-width: 768px) { /* Corrected from 7px to 768px */
  .large-image {
    max-height: 200px;
    object-fit: cover;
  }

  .tall-image {
    max-height: 400px;
    object-fit: cover;
  }

  .small-image,
  .square-image {
    max-height: 150px;
    object-fit: cover;
  }

  .home-featured-right {
    flex-direction: column;
  }

  .square-images-container {
    flex-direction: row;
    gap: 10px;
  }
}

.img1 {
  height: 200px;
}