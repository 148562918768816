body {
  margin: 0;
  background-color: #f32828;
  font-family: Verdana, sans-serif;
  font-size: 16px;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}


