/* Global Styles */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  background-color: #ffffff;
}

/* Breadcrumb Styling */
.breadcrumb {
  padding: 15px 20px;
  background-color: #FFFFFF;
  font-size: 14px;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top:50px;
}

.breadcrumb a {
  color: #0056b3;
  text-decoration: none;
  transition: color 0.3s;
}

.breadcrumb a:hover {
  color: #003580;
}

.breadcrumb span {
  color: #6c757d;
}

.container {
  display: flex;
  flex-direction: row; /* Keep image and details in a row (side by side) */
  justify-content: space-between;
  width: 100%;
  margin: 20px auto;
  padding: 20px 0px;
  background-color: #f3f3f5;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  color: #0056b3;
  max-width: 1200px;
  position: relative; /* Enable positioning for share buttons */
}

/* Make sure the image takes only the necessary space */
.product-image {
  flex: 1;
  padding: 20px;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}


/* Product Details Styling */
.product-details {
  flex: 2;
  padding: 20px;
}

.product-title {
  font-size: 28px;
  margin-bottom: 10px;
}

.product-description {
  font-size: 16px; /* Keep the font size readable */
  line-height: 1.6; /* Add a little extra line height for readability */
  margin-bottom: 20px;
  color: #333; /* Darker text for better contrast */
  text-align: justify; /* Left justify the text */
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Use a modern sans-serif font */
}

.product-description.limited {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.product-description.full {
  display: block;
}

/* Price Comparison Section */
.price-comparison {
  margin-top: 20px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.price-comparison h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

/* Vendor Cards Styling */
.vendor-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.vendor-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.vejndor-card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.vendor-name img {
  width: auto;
  height: 40px;
  border-radius: 4px;
  object-fit:fill
}

/* Responsive Image */
@media (max-width: 600px) {
  .vendor-name img {
      max-width: 80%; /* Adjust this value as needed */
      height: auto;
  }
}
.vendor-name {
  font-size: 1rem;
  color: #333;

  text-align: center;
}

.vendor-price {
  font-size: 1rem;
  color: #b12704;

  margin: 10px;
  text-align: center;
}

/* Buy Button Styling */
.buy-button {
  flex:1;
  background-color: #190a45;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s, transform 0.3s;
  justify-self: center;
}

.buy-button:hover {
  background-color: #0056b3;
  transform: translateY(-1px);
}

.share-buttons h3 {
  margin-right: 10px;
}

/* Share buttons should remain horizontal but move to the bottom */
.share-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* Align buttons to the left */
  gap: 10px;
  margin-top: 20px;
}

.share-buttons a {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #f0f0f0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.share-buttons a:hover {
  background-color: #e0e0e0;
}

.share-buttons a img {
  width: 20px;
  height: 20px;
}

/* Price Display Styling */
/* Price Styles */
.price-container {
  display: flex;
  gap: 20px;
  margin-top: 10px;
  margin: auto;
}

.best-price {
  font-family: 'Roboto', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Use a modern sans-serif font */
  font-size: 16px;
  margin: 10px 0;
}

.price-better {
  color: green;
}

.price-worse {
  color: red;
}

.price-same {
  color: blue;
}

.price-best {
  color: green; /* Cheapest price */
}

.price-mid {
  color: blue; /* Mid-range price */
}

.price-high {
  color: red; /* Most expensive price */
}

