.latest-blog-posts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .post-item {
    display: flex;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
    align-items: center;
  }
  
  .post-image img {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  
  /* Vertical separator styling */
  .vertical-separator {
    width: 1px;
    background-color: #ddd;
    height: 100%;
    margin-right: 5px;
  }
  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .post-item {
      flex-direction: row;
      align-items: flex-start;
    }
  
    .post-image img {
      width: 80px; /* Shrink image on smaller screens */
      margin-right: 10px;
    }
  
    .post-content h3 {
      font-size: 0.5em; /* Adjust title size */
      margin: 0px;
      text-align: justify;
    }
  
    .post-content p {
      font-size: 0.9em; /* Adjust excerpt size */
      display:none;
    }
  
    .read-more-link {
      font-size: 0.9em; /* Adjust link size */
    }
  }
  
  .post-content {
    display: flex;
    flex-direction: column;
  }
  
  .post-content h3 {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .post-content p {
    margin-bottom: 10px;
    text-align: justify;
  }
  
  .read-more-link {
    color: #007BFF;
    text-decoration: none;
  }
  
  .read-more-link:hover {
    text-decoration: underline;
  }
  