.slider-container {
  margin: 0 auto;
  text-align: center;
}

.horizontal-slider {
  width: 100%;
  height: 10px;
  background: transparent;
  border-radius: 5px;
  position: relative;
  margin-top: 10px;
}

.thumb {
  height: 20px;
  width: 20px;
  background: #190a45;
  border-radius: 50%;
  cursor: grab;
  position: absolute;
  top: -5px;
}

.track {
  height: 100%;
  position: absolute;
  z-index: 1;
}

.track-before, .track-after {
  background: rgba(0, 123, 255, 0.3); /* Opaque track */
}

.track-selected {
  background: #190a45; /* Selected range track */
}

.values {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
