
.log-in-button {
  background-color: white;
  color:#0d47a1;
  border: 1px solid;
  border-radius: 5px;
  width:85px;
  height:36px;
  margin:1em;
}
