.contact-container {
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  }
  
  .contact-container h1 {
    text-align: center;
    color: #1f2d5a;
  }
  
  .contact-container p {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    box-sizing: border-box;;
  }
  
  button[type="submit"] {
    background-color: #130a3f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type="submit"]:hover {
    background-color: #038f5a;
  }
  
  .form-status {
    text-align: center;
    margin-top: 20px;
    color: green;
  }
  .form-group textarea {
    height: auto; /* This will allow the textarea to grow dynamically with the content */
    padding: 12px; /* Extra padding for comfort */
    min-height: 150px; /* Ensure a minimum height */
  }


  