.about-container {
    max-width: 900px;
    margin: 50px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
    max-width: 1200px;
  }
  
 
  .about-container h1 {
    color: #1f2d5a;
    text-align: center;
  }
  
  .about-container p {
    line-height: 1.6;
    color: #333;
  }
  
  .about-container a {
    color: #0fb2e4;
    text-decoration: underline;
  }
  
  .about-container a:hover {
    text-decoration: underline;
  }
  