/* styles.css */
body {
    font-family: 'Roboto', sans-serif;
    background-color: white;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.search-container {
    display: flex;
}

.main-container {
    display: grid;
    grid-template-columns: 250px 1fr;
    gap: 20px;
    padding-top: 20px;
    max-width: 1200px;
    margin: 50px auto;
}

.filter-sidebar {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 100%;
}

.filter-sidebar h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
}

.filter-group {
    margin-bottom: 20px;
}

.filter-group h3 {
    font-size: 1.2em;
    margin-bottom: 10px;
}

.filter-group label {
    font-size: 1em;
    color: #333;
}

.apply-filters {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1em;
    width: 100%;
    transition: background-color 0.3s;
}

.apply-filters:hover {
    background-color: #0056b3;
}

.product-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
}

.product-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    overflow: hidden; /* Ensure content doesn't overflow the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
}

.image-container {
    width: 100%;
    height: 200px; /* Set a fixed height for the container */
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    border-radius: 15px 15px 0 0;
    display: flex;
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
}

.product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Ensure the image scales down to fit within the container */
    width: auto; /* Allows scaling based on aspect ratio */
    height: auto; /* Allows scaling based on aspect ratio */
}



.product-card h3 {
    margin: 15px 0;
    font-size: 1.4em;
    color: #333;
}

.product-card p {
    font-size: 1.2em;
    margin: 0px 0 5px;
    flex-grow: 1;
}

.product-card button {
    background-color: #190a45;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s;
    align-items: last baseline;
}

.product-card button:hover {
    background-color: #150837;
}

.product-card button:hover {
    background-color: #0056b3;
}

.product-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.product-card a {
    color: #ddd;
    text-decoration: none;
}

/* Media query for small screens */
@media (max-width: 810px) {
    .main-container {
        grid-template-columns: 1fr;
    }

    .filter-sidebar,
    .product-container {
        max-width: 100%;
        flex: 1 0 100%;
    }

    .filter-group {
        margin-bottom: 5px; /* Further reduce the spacing between filter groups */
    }

    .filter-group h3 {
        margin-bottom: 2px; /* Further reduce the spacing below each filter group title */
    }

    .filter-sidebar {
        padding: 10px; /* Reduce padding inside the sidebar */
    }

    .filter-group label {
        margin-bottom: 2px; /* Reduce space between labels */
    }
}

/* Search.css */

/* Style for the info block at the top of the page */
.info {
    background-color: #f9f9f9; /* Light grey background */
    border-left: 4px solid #0073e6; /* Blue border on the left */
    padding: 15px 20px; /* Padding inside the block */
    margin-bottom: 20px; /* Margin below the block */
    font-size: 1.1rem; /* Slightly larger font size */
    line-height: 1.6; /* Improved line spacing */
    color: #333; /* Dark grey text color */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    border-radius: 5px; /* Rounded corners */
    grid-column: 1 / -1; /* Ensure it spans across the entire grid */
    text-align: left; /* Align text to the left */
}

.product-container p  {
    margin:0px;
    padding:0px;
}
p.was-price  {

    padding:0px;
    margin: 5px;
}

p.price-drop {
    color:green;
    padding:5px;
}

/* Product Name */
p {
    margin: 10px 0;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
  }
  
  /* Product Price */
  .price {
    color: #e91e63;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 5px;
  }
