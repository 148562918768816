/* src/pages/Admin.css */

/* Container styles */
.admin-container {
  display: flex;
  min-height: 100vh;
  background-color: #f4f4f9;
  font-family: 'Arial', sans-serif;
}

/* Sidebar styles */
.sidebar {
  width: 200px; /* Adjusted width */
  padding: 20px;
  background-color: #2c3e50;
  color: white;
  border-right: 2px solid #34495e;
}

.sidebar h3 {
  margin-top: 0;
  font-size: 1.5em;
  border-bottom: 1px solid #34495e;
  padding-bottom: 10px;
}

.sidebar label {
  display: flex; /* Flexbox for alignment */
  align-items: center; /* Center items vertically */
  margin-top: 10px;
  font-size: 1.2em;
  cursor: pointer;
}

.sidebar input[type="checkbox"] {
  display: none; /* Hide default checkbox */
}

.sidebar input[type="checkbox"] + span {
  width: 16px;
  height: 16px;
  display: inline-block;
  border: 2px solid white;
  border-radius: 3px;
  margin-right: 10px;
  position: relative;
}

.sidebar input[type="checkbox"]:checked + span::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 2px;
}

/* Content styles */
.admin-content {
  flex: 1;
  padding: 20px;
}

.admin-content h1 {
  font-size: 2em;
  color: #2c3e50;
  border-bottom: 2px solid #2c3e50;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

/* Table styles */
table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

th, td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
}

th {
  background-color: #34495e;
  color: white;
  font-weight: bold;
  font-size: 1em;
}

td {
  font-size: 0.9em;
  color: #2c3e50;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

/* Button styles */
button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 5px;
}

button.edit {
  background-color: #3498db;
  color: white;
}

button.delete {
  background-color: #e74c3c;
  color: white;
}

button.save {
  background-color: #2ecc71;
  color: white;
}

button.cancel {
  background-color: #95a5a6;
  color: white;
}

/* Input styles */
input[type="text"], textarea {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 0.9em;
  box-sizing: border-box;
}

textarea {
  resize: vertical;
  height: 60px;
}
