/* CookieConsentBanner.css */

.cookie-consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #2b2b2b;
    color: #ffffff;
    z-index: 1000;
    padding: 20px;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
    font-size: 18px;
  }
  
  .cookie-consent-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .cookie-consent-buttons button {
    margin-left: 15px;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cookie-consent-buttons button:hover {
    background-color: #0056b3;
  }
  
  .cookie-consent-buttons button:nth-child(2) {
    background-color: #dc3545;
  }
  
  .cookie-consent-buttons button:nth-child(2):hover {
    background-color: #c82333;
  }
  
  /* Optional: Dim the rest of the page */
  .cookie-consent-banner::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  
  /* Add slide-in animation */
  @keyframes slideUp {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  
  .cookie-consent-banner {
    animation: slideUp 0.5s ease-out;
  }
  