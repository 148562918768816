.footer-container {
    padding: 20px;
    background-color: #f1f1f1;
    max-width: 1200px;
    margin: auto;
}

.footer-container ul {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer-container li {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.footer-container li:hover {
    background-color: #e0e0e0;
}

/* Footer.css */

.change-consent-button {
    margin-top: 20px;
    padding: 0px 0px;
    font-size: 16px;
    color: #0056b3;
    border: none;
    cursor: pointer;
  }
  

  
/* Media query for small screens */
@media (max-width: 768px) {
    .footer-container ul {
        grid-template-columns: repeat(3, 1fr);
    }
}
